import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";
import magic from "../../assets/magic.svg";
import TypewriterComponent from "typewriter-effect";

const HeroSection = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const sliderRef = useRef(null);
  const videoRefs = useRef([]);
  const [deviceSize, setDeviceSize] = useState('large');


  const videoInfo = [
    { title: "AI powered Student tracking", video: "https://raw.githubusercontent.com/Genuiro-Locus/videos/main/school.mp4" },
    { title: "AI powered Visitor Management", video: "https://raw.githubusercontent.com/Genuiro-Locus/videos/main/visitor.mp4" },
    { title: "Parcel Tracking", video: "https://raw.githubusercontent.com/Genuiro-Locus/videos/main/parcelVId.mp4" },
    { title: "AR T-shirt viewer", video: "https://raw.githubusercontent.com/Genuiro-Locus/videos/main/vr.mp4" },
    { title: "AR Tile viewer", video: "https://raw.githubusercontent.com/Genuiro-Locus/videos/main/tiles.mp4" },
    { title: "Digital business card", video: "https://raw.githubusercontent.com/Genuiro-Locus/videos/main/connectOu.mp4" },
    { title: "Home Tile viewer", video: "https://raw.githubusercontent.com/Genuiro-Locus/videos/main/home-inside.mp4" },
  ];


  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setDeviceSize('small');
      } else if (width >= 768 && width <= 1024) {
        setDeviceSize('medium');
      } else {
        setDeviceSize('large');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: (deviceSize === "large" ? 3 : 1),
    autoplay: true,
    speed: 500,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentVideoIndex(newIndex);
    },
  };

  const handleVideoEnded = () => {
    const nextIndex = (currentVideoIndex + 1) % videoInfo.length;
    setCurrentVideoIndex(nextIndex);
    setTimeout(() => {
      sliderRef.current.slickGoTo(nextIndex);
    }, 100); // Adding a slight delay to ensure smooth transition
  };

  useEffect(() => {
    if (videoRefs.current[currentVideoIndex]) {
      videoRefs.current[currentVideoIndex].load();
    }
  }, [currentVideoIndex]);

  return (
    <main id="home" className="section hero-section-new">
      <div className="top-section-new">
        <div className="magic">
          <div className="magic-img">
            <img src={magic} alt="Magic" />
          </div>
          <div>
            <p style={{ margin: "0px" }}>Making magic for your business</p>
          </div>
        </div>
        <div className="hero-text-section">
          {/* <div className="hero-head">
            <h1 className="head-gradient">Empowering Your Future with Innovation</h1>
          </div> */}
            <h1 className="head-gradient">Empowering Your Future with Innovation</h1>
        </div>
      </div>
      <div className="carousel-container">
        <Slider ref={sliderRef} {...settings}>
          {videoInfo.map((vid, index) => (
            <div
              key={index}
              className={`video-slide`}
              onMouseEnter={() => sliderRef.current.slickPause()}
              onMouseLeave={() => sliderRef.current.slickPlay()}
            >
              <div className={`container ${currentVideoIndex === index ? 'center-slide' : ''}`}>
                <span className="inner-span"></span>
                <span className="absolute-span bottom"></span>
                <span className="btn1"></span>
                <span className="btn2"></span>
                <span className="btn3"></span>
                <span className="btn4"></span>
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  muted
                  autoPlay
                  loop={true}
                  onCanPlay={() => {
                    if (index === currentVideoIndex) {
                      videoRefs.current[index].play().catch((error) => console.error("Error playing video:", error));
                    }
                  }}
                  onEnded={handleVideoEnded}
                  onError={(e) => console.error("Video playback error:", e)}
                >
                  <source src={vid.video} type="video/mp4" />
                </video>
              </div>
              {currentVideoIndex === index && (
                <p className="typewriter-text">
                  <TypewriterComponent
                    onInit={(typewriter) => {
                      typewriter.typeString(vid.title.toUpperCase()).start();
                    }}
                    options={{
                      loop: false,
                      delay: 100,
                      cursor: "|",
                    }}
                  />
                </p>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </main>
  );
};

export default HeroSection;
