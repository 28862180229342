import React, { useRef } from "react";
import Card from "./Card";
import html1 from "../../assets/flowbite_html-solid.svg";
import microPhone from "../../assets/nimbus_marketing.svg";
import vr from "../../assets/vr1.svg";
import android from "../../assets/material-symbols_android.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import "./styles.css";

const OurServices = () => {
  return (
    <section id="services" className=" section services-div">
      <div className="about-top">
        <div className="about-glow">Our Services</div>
        <div className="welcome-txt">
          <h1>Most Modern IT Services</h1>
        </div>
      </div>
      <div className="services">
        <div data-aos="fade-right" className="card1">
          <Card
            image={html1}
            title={"Web Development"}
            para={
              "Transform your online presence with Gotoz's web development services. We specialize in creating custom, responsive, and SEO-friendly websites tailored to meet your unique business needs"
            }
          />
        </div>
        <div data-aos="fade-down" className="card2">
          <Card
            image={microPhone}
            title={"Digital Marketing"}
            para={
              "Elevate your brand with Gotoz's digital marketing services. From SEO and content marketing to social media and paid advertising, we craft strategies that drive traffic, engage audiences, and boost conversions"
            }
          />
        </div>
        <div data-aos="fade-up" className="card3">
          <Card
            image={vr}
            title={"AR VR "}
            para={
              "Experience the future with Gotoz's AR/VR solutions. Our innovative technology provides immersive experiences using mobile devices, transforming how you interact with the digital world"
            }
          />
        </div>
        <div data-aos="fade-left" className="card4">
          <Card
            image={android}
            title={"App Development"}
            para={
              "Turn your ideas into reality with Gotoz's app development services. We design and develop custom, user-friendly apps for both iOS and Android, tailored to meet your specific business needs"
            }
          />
        </div>
      </div>
    </section>
  );
};

export default OurServices;
