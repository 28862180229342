import React, { useRef } from "react";
import "./styles.css";
import android from "../../assets/robo.svg";
import location from "../../assets/ion_location.svg";
import rightGradient from "../../assets/trackingBlob.png";
import connectleft from "../../assets/connect1left.png";

import arrow from "../../assets/arrow.svg";

import leftIphone from "../../assets/iPhone12leftPro.png";
import rightIphone from "../../assets/iPhone12right Pro.png";
import ipad from "../../assets/iPad.png";
import lock from "../../assets/lock2.svg";
import parcel from "../../assets/trackicon.png";
import ss1 from "../../assets/parceltrack1.png";
import ss2 from "../../assets/parceltrack2.png";
import parcetblob from "../../assets/parcelblob.png";

import faceIcon from "../../assets/faceicon.png";
import people from "../../assets/AIimage.png";
import notification from "../../assets/material-symbols-light_notifications.svg";
import management from "../../assets/hugeicons_folder-management.svg";
import chrome from "../../assets/Chrome.svg";

import gradientBall from "../../assets/blurGradient.svg";

import earth from "../../assets/Earth.svg";
import camera from "../../assets/Camera.png";
import vrheadset from "../../assets/vr-headset.png";
import vr1 from "../../assets/vr1.svg";
import tabredblob from "../../assets/tabredblob.png";
import tabblueblob from "../../assets/tab-gradientblob.png";
import tabandroid from "../../assets/android.png";
import leftIphone1 from "../../assets/connectou1-Photoroom.png";
import rightIphone1 from "../../assets/connectou2-Photoroom.png";
import qrS from "../../assets/cib_digital-ocean.svg";

import VrComponent from "./VrComponent";

const OurProducts = () => {
  return (
    <section id="my-prod" className="product-div">
      <div id="product" className="section"></div>
      <div className="about-top">
        <div className="about-glow">Our Products</div>
        <div className="welcome-txt">
          <h1>Futuristic AI Powered Products</h1>
        </div>
      </div>

      {/* Tracking app section */}
      <section data-aos="fade-up" className="product-section1">
        <div className="product-section st-product-section">
          <div className="product-content-section">
            <div
              data-aos="fade-left"
              className="product-title-desc product-title-desc1"
            >
              <h2 className="product-title st-title">
                AI Powered Student Tracking App{" "}
                <div className="title-android">
                  <img src={android} />
                </div>
              </h2>
              <p className="st-para">
                Ensure student safety with real-time updates and alerts,
                allowing parents and schools to track student's journeys to and
                from school.
              </p>
            </div>
            <div data-aos="fade-right" className="key-features key-features1">
              <div className="gap-div">
                <h2 className="product-title">Key Features</h2>
                <div className="list">
                  <div data-aos="fade-left" className="list-desc list-desc1">
                    <div className="features-img">
                      <img src={location} alt="location" />
                    </div>
                    <p>Real Time Location Tracking</p>
                  </div>
                  <div data-aos="fade-right" className="list-desc list-desc1">
                    <div className="features-img">
                      <img src={lock} alt="lock" />
                    </div>
                    <p>Secure Data Handling</p>
                  </div>
                  <div data-aos="fade-left" className="list-desc list-desc1">
                    <div className="features-img">
                      <img src={notification} alt="notification" />
                    </div>
                    <p>Notification For Parents</p>
                  </div>
                  <div data-aos="fade-right" className="list-desc list-desc1">
                    <div className="features-img">
                      <img src={management} alt="management" />
                    </div>
                    <p>Attendance Management</p>
                  </div>
                </div>
              </div>
              <div className="link-div">
                <a
                  target="blank"
                  className="visit-link"
                  href="https://www.gotoz.app/"
                >
                  {/* <img src={chrome} alt="chrome" /> */}
                  Visit site
                  <img src={arrow} alt="arrow" />
                </a>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className="product-image-section product-image-section1 st-img-section"
          >
            <img
              className="gradient-img-section"
              src={rightGradient}
              alt="img"
            />
            <img
              className="left-iphone-1 left-right"
              src={leftIphone}
              alt="img"
            />
            <img
              className="right-iphone-1 right-left"
              src={rightIphone}
              alt="ipone"
            />
          </div>
        </div>
      </section>

      {/* Ar vr concept */}
      <div data-aos="fade-up" className="product-section2">
        <div className="product-section ar-vr-section">
          <div
            data-aos="fade-left"
            className="product-image-section product-image-section2"
          >
            <div>
              <img
                src={ipad}
                style={{ width: "430px", height: "300px" }}
                alt="ipad"
              />
              <VrComponent />
            </div>
            <img className="red-blob" src={tabredblob} alt="tab" />
            <img src={tabblueblob} className="bottom-blob" alt="tab" />
            <img
              src={earth}
              style={{
                position: "absolute",
                width: "150px",
                left: "-3rem",
                bottom: "-3rem",
              }}
              alt="earth"
            />
            <img
              src={camera}
              style={{
                position: "absolute",
                width: "90px",
                height: "90px",
                right: "0rem",
                bottom: "3rem",
              }}
              alt="camera"
            />
            <img
              src={vrheadset}
              style={{
                position: "absolute",
                width: "100px",
                height: "70px",
                left: "0rem",
                top: "3rem",
              }}
              alt="vrheadset"
            />
            <img
              src={android}
              style={{
                position: "absolute",
                width: "100px",
                height: "70px",
                right: "0rem",
                top: "1rem",
              }}
              alt="android"
            />
          </div>
          <div className="product-content-section">
            <div
              data-aos="fade-right"
              className="product-title-desc product-title-desc2"
            >
              <h2 className="product-title">
                AR VR Immersive Mobile Expereince
              </h2>
              <p>
                Experience cutting-edge AR/VR technology that provides immersive
                and interactive experiences directly through mobile devices.
              </p>
            </div>
            <div data-aos="fade-left" className="key-features key-features2">
              <div className="gap-div">
                <h2 data-aos="fade-left" className="product-title">
                  Top Features
                </h2>
                <div className="list vr-list">
                  <div data-aos="fade-left" className="list-desc list-desc2">
                    <div className="features-img">
                      <img src={vr1} alt="vr1" />
                    </div>
                    {/* <div>
                      <p>High Quality AR VR</p>
                    </div> */}
                    <p>High Quality AR VR</p>
                  </div>
                  <div data-aos="fade-right" className="list-desc list-desc2">
                    <div className="features-img">
                      <img src={vr1} alt="vr1" />
                    </div>
                    <p>Compatible with various devices</p>
                  </div>
                  <div data-aos="fade-left" className="list-desc list-desc2">
                    <div className="features-img">
                      <img src={vr1} alt="vr1" />
                    </div>
                    <p>Customizable content</p>
                  </div>
                  <div data-aos="fade-right" className="list-desc list-desc2">
                    <div className="features-img">
                      <img src={vr1} alt="vr1" />
                    </div>
                    <p>User friendly Interface</p>
                  </div>
                </div>
              </div>
              <div className="vr-web-link">
                <a
                  target="blank"
                  className="visit-link link-div"
                  href="https://tiles.gotoztech.com/"
                >
                  {/* <img src={chrome} alt="chrome" /> */}
                  Visit site
                  <img src={arrow} alt="arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Connect ou */}
      <div data-aos="fade-up" className="product-section3">
        <div className="product-section connect-ou-section">
          <div
            data-aos="fade-left"
            className="product-image-section product-image-section3"
          >
            <img
              className="gradient-img-section"
              style={{ zIndex: "-10" }}
              src={connectleft}
              alt="iphone"
            />
            <img className="left-iphone-2" src={leftIphone1} alt="iphone" />
          </div>
          <div className="product-content-section">
            <div className="product-title-desc product-title-desc3">
              <h2 data-aos="fade-right" className="product-title">
                Digital Invitations and Business Card
              </h2>
              <p data-aos="fade-left">
                Create eco-friendly, customizable digital invitations and
                business cards that are easy to share and personalize, reducing
                paper waste and enhancing convenience
              </p>
            </div>
            <div className="key-features key-features3">
              <img
                src={gradientBall}
                style={{ position: "absolute", right: "-3rem", top: "-5rem" }}
                alt="ball"
              />
              <div className="gap-div">
                <h2
                  data-aos="fade-down"
                  className="product-title product-title3"
                >
                  Digital Features
                </h2>
                <div className="list list3">
                  <div data-aos="fade-left" className="list-desc list-desc3">
                    <div className="features-img">
                      <img src={qrS} alt="qr" />
                    </div>
                    <p>Customizable Template</p>
                  </div>
                  <div data-aos="fade-right" className="list-desc list-desc3">
                    <div className="features-img">
                      <img src={qrS} alt="qr" />
                    </div>
                    <p>Easy Sharing Social Media</p>
                  </div>
                  <div data-aos="fade-left" className="list-desc list-desc3">
                    <div className="features-img">
                      <img src={qrS} alt="qr" />
                    </div>
                    <p>ECO - Friendly and Paperless</p>
                  </div>
                  <div data-aos="fade-right" className="list-desc list-desc3">
                    <div className="features-img">
                      <img src={qrS} alt="qr" />
                    </div>
                    <p>High-quality Graphics</p>
                  </div>
                </div>
              </div>
              <div className="connectou">
                <a
                  target="blank"
                  className="visit-link link-div"
                  href="https://www.connectou.com/"
                >
                  {/* <img src={chrome} alt="chrome" /> */}
                  Visit site
                  <img src={arrow} alt="arrow" />
                </a>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className="product-image-section product-image-section3-2"
          >
            <img
              className="gradient-img-section2"
              style={{ zIndex: "-10", transform: "scalex(-1)" }}
              src={connectleft}
              alt="iphone"
            />

            <img
              className="right-iphone-2"
              style={{ marginRight: "3rem" }}
              src={rightIphone1}
              alt="iphone"
            />
          </div>
        </div>
      </div>
      {/* Parcel tracking */}
      <div data-aos="fade-up" className="product-section4">
        <div className="product-section st-product-section">
          <div className="product-content-section">
            <div
              data-aos="fade-left"
              className="product-title-desc product-title-desc1"
            >
              <h2 className="product-title st-title">
                Live Parcel Tracking ShipSecure
              </h2>
              <p className="st-para">
                The Live Parcel Tracking App provides real-time tracking,
                location updates, delivery notifications, and estimated delivery
                times for enhanced convenience.
              </p>
            </div>
            <div data-aos="fade-right" className="key-features key-features1">
              <div className="gap-div">
                <h2 className="product-title">Key Features</h2>
                <div className="list">
                  <div data-aos="fade-left" className="list-desc list-desc4">
                    <div className="features-img">
                      <img src={parcel} alt="parcel" />
                    </div>
                    <p>Live Parcel Trcaking</p>
                  </div>
                  <div data-aos="fade-right" className="list-desc list-desc4">
                    <div className="features-img">
                      <img src={parcel} alt="parcel" />
                    </div>
                    <p>Notification Alerts</p>
                  </div>
                  <div data-aos="fade-left" className="list-desc list-desc4">
                    <div className="features-img">
                      <img src={parcel} alt="parcel" />
                    </div>
                    <p>Interactive Maps</p>
                  </div>
                  <div data-aos="fade-right" className="list-desc list-desc4">
                    <div className="features-img">
                      <img src={parcel} alt="parcel" />
                    </div>
                    <p>Proof of Delivery</p>
                  </div>
                </div>
              </div>
              <div className="link-div">
                <a
                  target="blank"
                  className="visit-link"
                  href="https://www.gotoz.app/"
                >
                  {/* <img src={chrome} alt="parcel" /> */}
                  Visit site
                  <img src={arrow} alt="arrow" />
                </a>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className="product-image-section product-image-section1 st-img-section"
          >
            <img
              className="gradient-img-section parcel-blob"
              src={parcetblob}
              alt="parcel"
            />
            <img className="left-iphone-1 ss1" src={ss1} alt="iphone" />
            <img className="right-iphone-1 ss2" src={ss2} alt="iphone" />
          </div>
        </div>
      </div>
      {/* face recogentiton */}
      <div data-aos="fade-up" style={{ padding: "1rem" }}>
        <div className="product-section face-prod-section">
          <div
            data-aos="fade-left"
            className="product-image-section face-prod-section-img product-image-section2"
          >
            <img src={people} alt="people" />
          </div>
          <div className="product-content-section">
            <div
              data-aos="fade-right"
              className="product-title-desc product-title-desc2"
            >
              <h2 className="product-title">
                AI Face Recognition for Various Industrial Solutions
              </h2>
              <p>
                We offer AI face recognition solutions that enhance security,
                personalization, and efficiency across various industries.
              </p>
            </div>
            <div data-aos="fade-left" className="key-features key-features2">
              <div className="gap-div">
                <h2 data-aos="fade-left" className="product-title">
                  Top Features
                </h2>
                <div className="list vr-list">
                  <div data-aos="fade-left" className="list-desc list-desc5">
                    <div className="features-img">
                      <img src={faceIcon} alt="face" />
                    </div>
                    <p>Accurate Facial Recognize</p>
                  </div>
                  <div data-aos="fade-right" className="list-desc list-desc5">
                    <div className="features-img">
                      <img src={faceIcon} alt="face" />
                    </div>
                    <p>Secure Data Handling</p>
                  </div>
                  <div data-aos="fade-left" className="list-desc list-desc5">
                    <div className="features-img">
                      <img src={faceIcon} alt="face" />
                    </div>
                    <p>Applicable for Various Industries</p>
                  </div>
                  <div data-aos="fade-right" className="list-desc list-desc5">
                    <div className="features-img">
                      <img src={faceIcon} alt="face" />
                    </div>
                    <p>Real Time Processing</p>
                  </div>
                </div>
              </div>
              <div className="vr-web-link">
                <a
                  target="blank"
                  className="visit-link link-div"
                  href="https://tiles.gotoztech.com/"
                >
                  {/* <img src={chrome} alt="chrome" /> */}
                  Visit site
                  <img src={arrow} alt="arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProducts;
