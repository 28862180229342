import React, { useState, useEffect } from 'react';
import logo from '../../assets/OldLogo.svg';
import './styles.css';
import { TiThMenu } from 'react-icons/ti';
import { AiOutlineClose } from 'react-icons/ai';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const sections = document.querySelectorAll('.section');
    console.log('Sections:', sections);
  
    const options = {
      threshold: 0.1,
      rootMargin: '0px 0px -50% 0px',
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);
  
    sections.forEach((section) => observer.observe(section));
  
    return () => {
      // sections.forEach((section) => observer.unobserve(section));
    };
  }, []);
  


  return (
    <header className='header'>
      <div className="logo-section">
        <img className='gotoz-logo' src={logo} alt="Gotoz Logo" />
      </div>
      <div className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <nav className="nav">
          <ul>
            <li><a className={`nav-links ${activeSection === 'home' ? 'active' : ''}`} href="#home">Home</a></li>
            <li><a className={`nav-links ${activeSection === 'about' ? 'active' : ''}`} href="#about">About</a></li>
            <li><a className={`nav-links ${activeSection === 'services' ? 'active' : ''}`} href="#services">Services</a></li>
            <li><a className={`nav-links ${activeSection === 'product' ? 'active' : ''}`} href="#my-prod">Product</a></li>
          </ul>
        </nav>
      </div>
      <div className="contact-btn"><a href="#footer"><button className="contact-button">Contact</button></a></div>
      {/* <div className="hamburger-menu" onClick={toggleMenu}>
        {menuOpen ? <AiOutlineClose size={30} /> : <TiThMenu size={30} />}
      </div>
      {menuOpen && <div className="overlay" onClick={toggleMenu}></div>} */}
    </header>
  );
};

export default Header;
