import React, { useEffect } from 'react';
import "./styles.css";
import redBlur from "../../assets/Ellipse 9.svg";
import gradient from "../../assets/Ellipse 6.svg";
import gradientBlur from "../../assets/Ellipse 6.svg";
import bigBall from "../../assets/Ellipse 9.svg";

import instagram from "../../assets/instagram.svg";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import linkedin from "../../assets/linkedin.svg";
import SocialCard from './SocialCard';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Socials = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <section className='socials-div'>
            <div className='about-top'>
                <div className='about-glow'>Social Media</div>
                <div className='welcome-txt'><h1>Connect with Us on Social Media</h1></div>
            </div>
            <img src={redBlur} style={{ position: "absolute", right: "20rem" }} alt="blur" />
            <img src={gradient} style={{ position: "absolute", left: "20rem", bottom: "0.7rem" }} alt="gradient" />
            <img src={gradientBlur} style={{ position: "absolute", right: "25rem", top: "0.5rem" }} alt="blur2" />
            <img src={bigBall} style={{ position: "absolute", width: "100px", right: "15rem", bottom: "0.5rem" }} alt="ball2" />
            <div data-aos="fade-up" className='social-links1'>
                <div className='social-links'>
                    <div className='card-holder'><SocialCard img={instagram} text={"Instagram"} link={"https://www.instagram.com/gotoztech/"}/></div>
                    <div className='card-holder'><SocialCard img={facebook} text={"Facebook"} link={"https://www.facebook.com/profile.php?id=100089060979301"}/></div>
                    <div className='card-holder'><SocialCard img={twitter} text={"Twitter"} /></div>
                    <div className='card-holder'><SocialCard img={linkedin} text={"LinkedIn"} link={"https://www.linkedin.com/company/gotoz-techno-solutions-pvt-ltd"}/></div>
                </div>
            </div>
        </section>
    );
}

export default Socials;