import React from 'react';

const SocialCard = ({ img, text, link}) => {
    return (
        <div>
            <div className='card'>
                <div className="img">
                    <a target='blank' href={link}><img className='social-img' src={img} alt="icon" /></a>
                </div>
                <div className="social">
                    <h2>{text}</h2>
                    <a target='blank' href={link}><h3 className='followus'>Follow Us</h3 ></a>
                </div>
            </div>
        </div>
    );
}

export default SocialCard;