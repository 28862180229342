import React, { useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import achivements from "../../assets/Achivements.svg";
import gotoz from "../../assets/gotoz.svg";
import goal from "../../assets/octicon_goal-16.svg";
import square from "../../assets/material-symbols_square-outline.svg";
import triangle from "../../assets/triangle.svg";
import "./styles.css";

const AboutUs = () => {
  const aboutRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      offset: 100, // Offset (in px) from the original trigger point
    });
  }, []);

  return (
    <section ref={aboutRef} id='about' className='section about-us-div'>
      <div className='about-top'>
        <div className='about-glow'>About us</div>
        <div className='welcome-txt'><h1>Welcome</h1></div>
      </div>
      <div className="blob-section">
        <div data-aos="fade-right" className='blob-right1' style={{ position: "relative" }}>
          <img src={square} style={{ position: 'absolute' }} alt="square" />
          <img src={triangle} style={{ position: 'absolute' }} alt="triangle" />
          <img src={triangle} style={{ position: 'absolute', top: "2rem", right: "0" }} alt="triangle" />
          <div className='blob-content-section blob-right'>
            <div className="badge-section">
              <img className='badge-image' src={achivements} alt="achivements" />
            </div>
            <div className="text-section">
              <p>We deliver transformative solutions that enhance global interactions with technology consistently adding value and innovation.</p>
              <h2>Our Achievements</h2>
            </div>
          </div>
          <img src={square} style={{ position: 'absolute', bottom: "0" }} alt="square" />
        </div>
        <div style={{ position: "relative" }} data-aos="fade-down" className='blob-center'>
          <img src={square} style={{ position: 'absolute', top: "3rem", width: "25px" }} alt="square" />
          <img src={square} style={{ position: 'absolute', width: "25px", bottom: "0", right: "0" }} alt="square" />
          <img src={triangle} style={{ position: 'absolute', bottom: "0", left:"3", width:"20px"}} alt="triangle" />
          <img src={triangle} style={{ position: 'absolute', width: "30px", height: "30px", top: "16rem", right: "-2rem" }} alt="triangle" />
          <div className='blob-content-section blob-center'>
            <div className="badge-section">
              <img className='badge-image' src={gotoz}  />
            </div>
            <div className="text-section">
              <p>At Gotoz, we pioneer technological innovations that empower individuals and businesses, driven by integrity, creativity, and excellence</p>
              <h2>Our Company</h2>
            </div>
          </div>
          
        </div>
        <div data-aos="fade-left" className='blob-left1' style={{ position: "relative" }}>
          <img src={triangle} style={{ position: 'absolute', }} alt="triangle" />
          <div data-aos="fade-up" className='blob-content-section blob-left'>
            <div className="badge-section">
               <img className='badge-image' src={goal} alt="badge" />
            </div>
            <div className="text-section">
              <p>Our mission is to create solutions that simplify tasks, enhance digital experiences, and foster connectivity, with a focus on customer satisfaction and ethical practices</p>
              <h2>Our Goal</h2>
            </div>
          </div>
          <img src={square} style={{ position: 'absolute', top: "0", right: "0" }} alt="square" />
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
