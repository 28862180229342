import { Canvas } from '@react-three/fiber'
import { useGLTF, AccumulativeShadows, RandomizedLight, Environment, CameraControls, OrbitControls } from '@react-three/drei'
import "./styles.css"

export default function VrComponent() {
  return (
    <Canvas className='vr-canvas' shadows camera={{ position: [5, 0, 5], fov: 65 }}>
      <ambientLight intensity={Math.PI} />
      <VrHead position={[0, 0, 0.85]} />
      {/* <VrHead position={[0, 0, -0.85]} rotation={[0, 0.5, Math.PI]} scale={-1} /> */}
      <AccumulativeShadows position={[0, -0.5, 0]} temporal frames={100} alphaTest={0.75} opacity={0.9}>
        <RandomizedLight radius={6} position={[5, 5, -10]} bias={0.001} />
      </AccumulativeShadows>
    <OrbitControls OrbitControls autoRotate/>
      <Environment preset='city'/>
    </Canvas>
  )
}

// export function VrHead(props) {
//     const { nodes, materials } = useGLTF('/VR Real.glb')
//     return (
//       <group {...props} dispose={null}>
//         <group rotation={[Math.PI / 2, 0, 0]}>
//           <mesh
//             castShadow
//             receiveShadow
//             geometry={nodes.Plane_1.geometry}
//             material={materials['Material.004']}
//           />
//           <mesh
//             castShadow
//             receiveShadow
//             geometry={nodes.Plane_2.geometry}
//             material={materials['Material.005']}
//           />
//           <mesh
//             castShadow
//             receiveShadow
//             geometry={nodes.Plane_3.geometry}
//             material={materials['Material.006']}
//           />
//         </group>
//       </group>
//     )
//   }

export function VrHead(props) {
    // const { nodes, materials } = useGLTF('/vr.gltf')
    // return (
    //   <group {...props} dispose={null}>
    //     <group rotation={[Math.PI / 2, 0, 0]}>
    //       <mesh
    //         castShadow
    //         receiveShadow
    //         geometry={nodes.Plane_1.geometry}
    //         material={materials['Material.004']}
    //       />
    //       <mesh
    //         castShadow
    //         receiveShadow
    //         geometry={nodes.Plane_2.geometry}
    //         material={materials['Material.005']}
    //       />
    //       <mesh
    //         castShadow
    //         receiveShadow
    //         geometry={nodes.Plane_3.geometry}
    //         material={materials['Material.006']}
    //       />
    //     </group>
    //   </group>
    // )
    // const { nodes, materials } = useGLTF('New_VR_2.gltf')
    const { nodes, materials } = useGLTF('https://raw.githubusercontent.com/Genuiro-Locus/videos/main/New_VR_2.gltf');
    return (
      <group {...props} dispose={null}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Plane_1.geometry}
            material={materials['Material.004']}
          />
          <mesh
            geometry={nodes.Plane_2.geometry}
            material={materials['Material.005']}
          />
          <mesh
            geometry={nodes.Plane_3.geometry}
            material={materials['Material.006']}
          />
          <mesh
            geometry={nodes.Plane_4.geometry}
            material={materials['strap colour']}
          />
        </group>
      </group>
    );
  }