import React, { useEffect } from 'react';
import "./App.css"
import HeroSection from './Components/HeroSection';
import Header from "./Components/Header"
import AboutUs from './Components/AboutUs';
import OurServices from './Components/OurServices';
import OurProducts from './Components/OurProducts';
import OurTeam from './Components/OurTeam';
import ClientReview from './Components/ClientReview';
import Socials from './Components/Socials';
import Footer from './Components/Footer';

const App = () => {
  
  return (
    <div className=''>
      <Header/>
      <HeroSection/>
      <AboutUs/>
      <OurServices/>
      <OurProducts/>
      <OurTeam/>
      <ClientReview/>
      <Socials/>
      <Footer/>
    </div>
  );
};

export default App;
