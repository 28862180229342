import React from 'react'
import "./styles.css"
import logo from "../../assets/OldLogo.svg"
import instagarm from "../../assets/instagram.svg"
import facebook from "../../assets/facebook.svg"
import twitter from "../../assets/twitter.svg"
import linkedin from "../../assets/linkedin.svg"
import mail from "../../assets/mail.svg"
import location from "../../assets/Location.svg"
import whatsapp from "../../assets/whatsapp.svg"


const Footer = () => {
    return (
        <footer id='footer' className='footer-div'>
            <div className='about-top'>
                <div className='about-glow'>Contact us</div>
                <div className='welcome-txt'><h1>Get in Touch with Us</h1></div>
                <div className='footer-text'><p>We'd love to hear from you! Whether you have a question about our products, need support, or want to learn more about how we can help your business, our team is here to assist you.</p></div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "center", alignItems: "center" }}>
                <div><img src={logo} style={{width:"200px"}} alt="logo" /></div>
                <div className='gradirent-div'>
                    <div>BECAUSE YOU CANT BE EVERYWHERE</div>
                </div>
                <div className='social-images'>
                    <div><a target='blank' href="https://www.instagram.com/gotoztech/"><img width={30} src={instagarm} alt="instagram" /></a></div>
                    <div><a target='blank' href="https://www.facebook.com/profile.php?id=100089060979301"><img width={30} src={facebook} alt="facebook" /></a></div>
                    <div><a target="blank" href=""><img width={30} src={twitter} alt="twitter" /></a></div>
                    <div><a target='blank' href="https://www.linkedin.com/company/gotoz-techno-solutions-pvt-ltd"><img width={30} src={linkedin} alt="linkedin" /></a></div>
                    <div><a target='blank' href={`https://wa.me/${+919363543137}?text=${encodeURIComponent("Message")}`}><img width={30} src={whatsapp} alt="whatsapp" /></a></div>
                </div>

            </div>
            <div className='mail-location'>
                <div className='mail'><a href="mailto:info@gotox.app"><img src={mail} alt="mail" />info@gotoz.app</a></div>
                <div className='locations'>
                    <div className='location'>
                        <img src={location} alt="location" />
                        <p>India</p>
                    </div>
                    <div className='location'>
                        <img src={location} alt="location" />
                        <p>Dubai</p>
                    </div>
                </div>
            </div>
            <div className='copyrights'><p>© 2024 Gotoz Inc. All rights reserved. Unauthorized use is prohibited. Excerpts and links may be used with credit to Gotoz Inc</p></div>
        </footer>
    )
}

export default Footer