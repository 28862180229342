import React from 'react'
import "./styles.css"
const Info = ({ img, name, designation, emps }) => {
    return (
        <div className='info'>
            <div className='image-section CEO'>
                <img className={`profile-img ${emps ? "emps-img":""}`} src={img} alt="employee" />
            </div>
            <div className='name-desigantion'>
                <p className="name">{name}</p>
                <h2 className='designation'>{designation}</h2>
            </div>
        </div>
    )
}

export default Info