import React, { useRef } from 'react'
import "./styles.css"
import CEO from "../../assets/Team/CEO.svg"
import Vidhya from "../../assets/Team/Nithya.svg"
import Balaji from "../../assets/Team/Balaji.svg"
import Sheema from "../../assets/Team/Sheema.svg"
import Karthikeyan from "../../assets/Team/Karthikeyan.svg"
import Abinaya from "../../assets/Team/Abinaya.svg"
import ShanmugaPriyan from "../../assets/Team/Shanmugapriyan.svg";
import Manikandan from "../../assets/Team/Manikandan.svg"
import Iyappan from "../../assets/Team/Prabhu.svg"
import Jayakumar from "../../assets/Team/Jayakumar.svg"
import Hari from "../../assets/Team/Hari.svg"
import Tamil from "../../assets/Team/Tamil.svg"
import Janani from "../../assets/Team/Janani.svg"
import Dhinakar from "../../assets/Team/Dhinakar.svg"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Info from './Info'


const OurTeam = () => {

    return (
        <section className='our-team'>
            <div className='about-top our-team-top'>
                <div className='about-glow'>Our Team</div>
                <div className='welcome-txt'><h1>Meet Our Team</h1></div>
                {/* <div className='team-text'><p>At Gotoz, our team of dedicated professionals drives our success and innovation. From our experienced leadership to our creative developers, each member plays a crucial role in delivering exceptional products and services</p></div> */}
            </div>
            <div className="team-imgs">
                <div className="top-imgs">
                    {/* <div data-aos="fade-down" className='top-ceo'>
                        <Info img={CEO} name={"Madheswaran Thangavel"} designation={"CEO"} />
                    </div> */}
                    {/* <div data-aos="fade-left">
                        <Info emps={true} img={Vidhya} name={"Vidhya"} designation={"Chennai region sales head"} />
                    </div> */}
                    {/* <div data-aos="fade-down" className='middle-ceo'>
                        <Info img={CEO} name={"Madheswaran Thangavel"} designation={"Chief Executive Officer"} />
                    </div> */}
                    <div data-aos="fade-down">
                        <Info img={Dhinakar} name={"Dhinakaran"} designation={"Chief Operating Officer "} />
                    </div>

                </div>
                <div className='mid-imgs'>
                    <div data-aos="fade-left">
                        <Info emps={true} img={Abinaya} name={"Abinaya"} designation={"Software Engineer"} />
                    </div>
                    <div data-aos="fade-left">
                        <Info emps={true} img={Karthikeyan} name={"Karthikeyan"} designation={"Software Engineer"} />
                    </div>
                    <div data-aos="fade-right">
                        <Info emps={true} img={Balaji} name={"Sri Balaji"} designation={"UI UX Engineer"} />
                    </div>
                    <div data-aos="fade-right">
                        {/* <Info emps={true} img={Manikandan} name={"Manikandan"} designation={"Software Engineer"} /> */}
                        <Info emps={true} img={Sheema} name={"Sheema"} designation={"Software Engineer"} />

                    </div>
                    <div data-aos="fade-right">
                        <Info emps={true} img={ShanmugaPriyan} name={"Shanmugapriyan"} designation={"AR Developer"} />
                    </div>
                </div>
                <div className='low-imgs'>
                    {/* <div data-aos="fade-left">
                        <Info emps={true} img={Iyappan} name={"PrabuIyyappan"} designation={"Sales and Marketing"} />
                    </div> */}
                    <div data-aos="fade-left">
                        <Info emps={true} img={Jayakumar} name={"Jeyakumar"} designation={"Sales and Marketing"} />
                    </div>
                    <div data-aos="fade-left">
                        <Info emps={true} img={Hari} name={"Hari Prasath"} designation={"Flutter Developer"} />
                    </div>
                    <div data-aos="fade-right">
                        <Info emps={true} img={Tamil} name={"Tamilarasan"} designation={"Web Developer"} />
                    </div>
                    <div data-aos="fade-right">
                        <Info emps={true} img={Janani} name={"Janani"} designation={"UI Developer"} />
                    </div>
                    <div data-aos="fade-right">
                    <Info emps={true} img={Manikandan} name={"Manikandan"} designation={"Software Engineer"} />

                        {/* <Info emps={true} img={Sheema} name={"Sheema"} designation={"Software Engineer"} /> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurTeam;