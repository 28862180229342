import React, { useRef } from 'react'
import "./styles.css";
import customer1 from "../../assets/customers/customer1.svg"
import customer2 from "../../assets/customers/customer2.svg"
import customer3 from "../../assets/customers/customer3.svg"
import customer4 from "../../assets/customers/customer4.svg"
import customer5 from "../../assets/customers/customer5.svg"
import customer6 from "../../assets/customers/customer6.svg"
import customer7 from "../../assets/customers/customer7.svg"
import AOS from 'aos';
import 'aos/dist/aos.css';


const ClientReview = () => {

    return (
        <section className='review-div'>
            <div className='about-top'>
                <div className='about-glow'>Client Review</div>
                <div className='welcome-txt'><h1>What Our Clients Says</h1></div>
            </div>
            <div className='review-section'>
                <div data-aos="fade-left" className="left-section">
                    <div className='customer-img'>
                        <img src={customer1} alt="c1" />
                    </div>
                    <div className='customer-img'>
                        <img src={customer2} alt="c2" />
                    </div>
                    <div className='customer-img'>
                        <img src={customer3} alt="c3" />
                    </div>
                </div>
                <div data-aos="fade-down" className="center-section">
                    <div className='center-content-div'>
                        <p>At Gotoz, we pride ourselves on delivering exceptional products and services. But don’t just take our word for it—here’s what our clients have to say.</p>
                        <img src={customer4} alt="c4" />
                    </div>
                </div>
                <div data-aos="fade-right" className="right-section">
                    <div className='customer-img'>
                        <img src={customer5} alt="c4" />
                    </div>
                    <div className='customer-img'>
                        <img src={customer6} alt="c5" />
                    </div>
                    <div className='customer-img'>
                        <img src={customer7} alt="c6" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ClientReview