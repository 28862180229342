import React from 'react'
import "./styles.css"

const Card = ({image, title, para}) => {
  return (
    <div className='my-card'>
        <div className="icon-section">
            <img src={image} alt="card-img" />
        </div>
        <div className='card-text-section'>
            <h3>{title}</h3>
            <p>{para}</p>
        </div>
    </div>
  )
}

export default Card